import styles from "../styles/Vignette.module.css";
import { Price } from "./Price";
import Link from "next/link";
import React, { useEffect, useState } from "react";
import { PriceContext } from "../lib/types";
import { SuperImage } from "./SuperImage"

export default function Vignette({
  product,
  pic,
  priceContext,
  priority = false,
  optim,
}: {
  product: any;
  pic: number;
  priceContext?: PriceContext;
  priority: boolean;
  optim?: any,
}) {
  const { url, name, pics, disablehoverpic } = product;
  const [secondSel, setSecondSel] = useState(0);
  const _pick = () => {
    const n = pics.length;
    const rd = Math.floor(Math.random() * (n - 1));
    setSecondSel((pic + 1 + rd) % n);
  };
  const _leave = () => setTimeout(_pick, 400); // must be longer than opacity transition
  useEffect(_pick, [pics, pic]);
  const dir = priceContext === "braderie"
    ? "vide-atelier"
    : "shop"

  return (
    <Link href={`/${dir}/${url}`}>
      <div className="relative">
        <SuperImage
          pic={pics[pic]}
          priority={priority}
          optim={optim}
          alt={`${name} Marianne by Marie Jordane`}
          horizontalCropRatio={0}
          className=""
        />
        {pics.length > 1 && !disablehoverpic && (
          <div
            className="absolute inset-0 opacity-[0] hover:opacity-[1] transition ease-in-out duration-300"
            onMouseLeave={_leave}
          >
            <SuperImage
              pic={pics[secondSel]}
              priority={false}
              optim={optim}
              alt={`${name} Marianne by Marie Jordane`}
              horizontalCropRatio={0}
              className=""
            />
          </div>
        )}
      </div>
      <div className="p-[5px] flex justify-between flex-wrap">
        <div className={styles.name}>{name}</div>
        <Price product={product} context={priceContext} />
      </div>
    </Link>
  )
}
