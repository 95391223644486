import styles from '../styles/Home.module.css'
import { api } from '../lib/common'
import { ButtonLink, ExternalLink, Pic } from '../components/basics'
import { Avantages, Footer } from '../components/Footer'
import { LINKS } from '../lib/constants'
import { useTranslations } from 'next-intl'
import { useRef, useEffect } from 'react'
import { Collection2 } from '../components/Collection2'
import { ProductsContext } from '../contexts/ProductsContext'

export async function getStaticProps({ locale }) {
    const home = await api('content', {
        page: 'collection',
        url: '_home',
        locale
    })
    return {
        props: { ...home },
    }
}

export default function Home({ collection, products }) {
    // const { home_desk } = site
    // const { pics } = home_desk
    // const t = useTranslations("home")
    // const videoRef = useRef(null)

    // useEffect(() => {
    //     const observer = new IntersectionObserver(([entry]) => {
    //         if (entry.isIntersecting) {
    //             videoRef.current.play()
    //         }
    //     })
    //     observer.observe(videoRef.current)

    //     return () => {
    //         observer.disconnect()
    //     };
    // }, [videoRef])

    return (
        <div className={styles.page}>
            {/* <pre>{JSON.stringify(pics)}</pre> */}
            <ProductsContext.Provider value={{products}}>
                <Collection2 collection={collection} products={products} />
            </ProductsContext.Provider>

            {/* <div className="md:grid grid-cols-2 grid-flow-row-dense">
                <h2 className="mb-4">{t('clr')}</h2>
                <div className="md:row-span-2 md:col-start-2">
                    <video
                        ref={videoRef}
                        src="https://bmj.leb.run/catalog/confection.mp4"
                        controls preload="none" muted loop playsInline
                        poster="/video/confection.jpeg" />
                </div>
                <div className="">
                    <p>{t('clr1')}</p>
                    <p>{t('clr2')}</p>
                    <p>{t('clr3')}</p>
                    <div>
                        <ExternalLink href={LINKS.fabrication} type="secondary">{t('plus')}</ExternalLink>
                    </div>
                </div>
            </div> 
 
            <div className={styles.adresses}>
                <div className={styles.videodesktopcontainer}>
                    <Pic pic={pics[1]} />
                </div>
                <div>
                    <h2>{t('na')}</h2>
                    <p>{t('na1')}<br />{t('na1b')}</p>
                    <p>{t('na2')}</p>
                    <p>{t('na3')}</p>
                    <p><ExternalLink href={LINKS.rdv} type="secondary">{t('nardv')}</ExternalLink></p>

                    <p>{t('na4')}<br />{t('na5')}<br />{t('na6')}</p>
                </div>
            </div>

            <div className={styles.engagements}>
                <div>
                    <h2 className="mb-2">{t('eng')}</h2>
                    <Pic pic={pics[2]} className={styles.videomobile} />
                    <p>{t('eng1')}</p>
                    <p>{t('eng2')}<br />{t('eng3')}</p>
                    <p>{t('eng4')}</p>
                </div>
                <Pic pic={pics[2]} className={styles.videodesktopcontainer} />
            </div>
            <div>
                <ExternalLink href={LINKS.engagements} type="secondary">{t('plus')}</ExternalLink>
            </div>

            <div className={styles.poing}>
                <Pic pic={pics[3]} />
                <Pic pic={pics[4]} />
                <Pic pic={pics[5]} />
            </div>

            <div>
                <p>{t('home3')}</p>
                <p>{t('home4')}</p>
                <p>{t('home5')}</p>
            </div>

            <div className={styles.goshop}>
                <ButtonLink href="/shop" type="primary" className="block m-2">Shop</ButtonLink>
            </div>

            <div>
                <Pic pic={pics[6]} />
            </div> */}

            <Avantages />
            <Footer />
        </div>
    )
}
