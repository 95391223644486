// internal or external links
// - external: use <a>, clicks are tracked
// - internal: use <Link>
// type 'link' (normal link) or 'primary' or 'secondary' (button look) 

import Link from 'next/link';
import { useRouter } from 'next/router';
import { track } from '../lib/actions';
import { useAppDispatch } from '../lib/store';
import styles from './SuperLink.module.css'

export const SuperLink = ({ href, type = 'link', target, width = 'fit', children, }) => {
  const router = useRouter()
  const dispatch = useAppDispatch()
  const internal = href && href[0] === "/"
  const cn = styles[type] + " " + styles[width]

  if (internal) return (
    <Link href={href} target={target} className={cn}>
      {children}
    </Link>
  )

  return (
    <a href={href}
      className={cn}
      onClick={() => {
        dispatch(track({ event: "nav-external", href }))
        return true // use href now
      }}
      target={target}
    >
      {children}
    </a>
  )
}
