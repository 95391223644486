import React, { useContext, useEffect, useRef, useState } from "react"
import Vignette from "../components/Vignette"
import styles from "./Collection.module.css"
import { ProductsContext } from "../contexts/ProductsContext"
import Link from "next/link"
import parse, { domToReact } from "html-react-parser"
import { SuperLink } from "./SuperLink"
import { SuperImage } from "./SuperImage"
import { cx, imgsrc } from "../lib/common"
import { SecondaryButton } from "./PrimaryButton"

const IGNORE_ROW_ATTRS = new Set(["id-", "items-"])
const IGNORE_ITEM_ATTRS = new Set([
  "id-",
  "product-",
  "text-body-",
  "text-body_en-",
  "pic-src-",
  "pic-dim-",
])

export const Collection2 = ({ collection }) => {
  const { name, rows, priceContext, lockCode } = collection
  // notice the `key` prop used to instantiate Collection2 in order to force
  // state reset when navigating between collections */}
  const [locked, setLocked] = useState(
    (typeof lockCode === "string" && lockCode.length > 0)
  )

  // can't do that in state initialization or hydration will fail
  useEffect(() => {
    if (typeof window !== "undefined" &&
    window.localStorage.getItem(`code_${name}`) === lockCode) {
      setLocked(false)
    }
  }, [])

  const handleUnlock = () => {
    window.localStorage.setItem(`code_${name}`, lockCode)
    setLocked(false)
  }

  return locked ? (
    <Locked lockCode={lockCode} onUnlock={handleUnlock} />
  ) : (
    <div className={styles.grid}>
      {rows.map((row, rowIndex) => {
        const count = {
          mobile: row.items.filter((i) => i.device?.mobile !== false).length,
          desktop: row.items.filter((i) => i.device?.desktop !== false).length,
        }
        const rowClasses = classes({ ...row, count }, IGNORE_ROW_ATTRS)
        const priority = rowIndex < 5

        return (
          <React.Fragment key={row.id}>
            {row.items.map((item, itemIndexOnRow) => {
              const sizeOptim = optim(
                item.layout,
                row.wraponmobile === "rtl" || row.wraponmobile === "ltr"
              )

              return (
                <Item
                  key={item.id}
                  item={{ ...item, optim: sizeOptim, priceContext }}
                  rowClasses={rowClasses}
                  priority={priority}
                  order={
                    rowIndex * 1000 +
                    (row.wraponmobile === "rtl"
                      ? 100 - itemIndexOnRow
                      : itemIndexOnRow)
                  }
                />
              )
            })}
          </React.Fragment>
        )
      })}
    </div>
  )
}

const Item = ({ item, optim, rowClasses, priority, order }) => {
  const c = classes(item, IGNORE_ITEM_ATTRS)
  return (
    <div
      className={cx(styles.cell, c, rowClasses)}
      style={{ "--mobile-order": order }}
    >
      {withText(withLink(itemSwitch(item, priority), item), item)}
      {/* <div>{JSON.stringify(item, null, 2)}</div>
      <div>{c}</div> */}
    </div>
  )
}

const Product = ({ item, priority }) => {
  const { product, photo, priceContext } = item
  const { products } = useContext(ProductsContext)
  const prod = products[product] || { name: "missing " + product }
  return (
    <Vignette
      product={prod}
      pic={photo || 0}
      priceContext={priceContext}
      priority={priority}
      optim={item.optim}
    />
  )
}

const Image = ({ item, priority }) => {
  const { pic, cropOnMobile, cropRatio } = item
  return (
    <SuperImage
      pic={pic}
      priority={priority}
      optim={item.optim}
      horizontalCropRatio={cropOnMobile && cropRatio}
    />
  )
}

const Youtube = ({ item }) => {
  const { youtubeUrl } = item
  return (
    // aspect-ratio polyfill for 16/9 videos
    <div className="relative pb-[56.25%]">
      <iframe
        className="absolute top-0 left-0 w-full h-full"
        src={`https://www.youtube.com/embed/${youtubeUrl}`}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      />
    </div>
  )
}

const Video = ({ item }) => {
  const { video, pic } = item
  const videoRef = useRef(null)
  const autoPlayedOnce = useRef(false) // ! useState would be captured by the callback

  useEffect(() => {
    try {
      const observer = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting && !autoPlayedOnce.current) {
          videoRef.current.play()
          autoPlayedOnce.current = true
        }
      })
      observer.observe(videoRef.current)

      return () => {
        observer.disconnect()
      }
    } catch (error) {
      console.log("IntersectionObserver not supported")
    }
  }, [videoRef])

  return (
    <video
      ref={videoRef}
      src={imgsrc(video.src)}
      controls
      preload="none"
      muted
      loop
      playsInline
      poster={imgsrc(pic.src)}
    />
  )
}

const Button = ({ item }) => {
  const { link, width, type, label } = item
  const { href, target } = link
  return (
    <SuperLink href={href} type={type} target={target} width={width}>
      {label}
    </SuperLink>
  )
}

const Text = ({ item }) => {
  return (
    <div className={styles.withtext}>
      {item.text?.body && parseHTML(item.text.body)}
    </div>
  )
}

const withText = (component, item) => {
  const { withtext, text } = item
  if (!withtext || component.type === Text) return component
  return (
    <>
      {component}
      <div className={styles.withtext}>
        {text && text.body && parseHTML(text.body)}
      </div>
    </>
  )
}

const withLink = (component, item) => {
  const { href, target } = item.link || {}
  if (!href || href === "" || item.kind === "button") return component
  if (href[0] === "/")
    return (
      <Link href={href} target={target}>
        {component}
      </Link>
    )
  // TODO add tracking
  return (
    <a href={href} target={target}>
      {component}
    </a>
  )
}

const itemSwitch = (item, priority) => {
  switch (item.kind) {
    case "product":
      return <Product item={item} priority={priority} />
    case "text":
      return <Text item={item} />
    case "image":
      return <Image item={item} priority={priority} />
    case "youtube":
      return <Youtube item={item} />
    case "video":
      return <Video item={item} />
    case "button":
      return <Button item={item} />
    default:
      return <div>{item.kind}</div>
  }
}

const parseHTML = (html) => {
  return parse(html, {
    replace: (domNode) => {
      if (domNode.type === "tag" && domNode.name === "a") {
        const {
          "data-type": type,
          "data-href": href,
          "data-target": target,
          "data-width": width,
        } = domNode.attribs
        if (!type) return null
        return (
          <SuperLink href={href} type={type} target={target} width={width}>
            {domToReact(domNode.children)}
          </SuperLink>
        )
      }
    },
  })
}

const classes = (obj, ignore, path = "") => {
  if (ignore.has(path)) return ""

  if (typeof obj !== "object")
    return styles[path + obj] || `debug[${path + obj}]`

  return Object.keys(obj)
    .map((k) => classes(obj[k], ignore, path + k + "-"))
    .join(" ")
}

// determine the SuperImage `sizes` and `fallbackWidth` attributes for srcset optimization etc.
const optim = (layout, wraponmobile) => {
  const s = (mobile, desktop) => {
    const m = `${Math.floor((mobile / 2) * 100)}vw`
    const d = `${Math.floor((desktop / 12) * 1200)}px`
    return {
      sizes: `(max-width: 768px) ${m}, ${d}`,
      fallbackWidth: { m, d },
    }
  }
  switch (layout) {
    case "XXS":
      return s(wraponmobile ? 2 : 1, 3)
    case "XS":
      return s(wraponmobile ? 2 : 1, 4)
    case "S":
      return s(wraponmobile ? 2 : 1, 5)
    case "M":
      return s(wraponmobile ? 2 : 1, 6)
    case "L":
      return s(wraponmobile ? 2 : 1, 7)
    case "XL":
      return s(2, 10)
    case "Banner":
      return s(2, 12)
    default:
      return s(2, 12)
  }
}

const Locked = ({ lockCode, onUnlock }) => {
  const [code, setCode] = useState("")
  const [codeError, setCodeError] = useState(null)
  const submitCodeButtonRef = useRef()
  const inputRef = useRef()
  useEffect(() => {
    inputRef.current.focus()
  }, [])

  const handleSubmit = () => {
    if (code === lockCode) {
      onUnlock()
      return Promise.resolve()
    } else {
      setCodeError("no")
      return Promise.reject()
    }
  }

  return (
    <div className="flex flex-col items-center w-full p-4">
      <div className="w-full max-w-lg  flex flex-col gap-4 py-4">
        <div>Avez-vous le code secret ?</div>
        <div className="flex">
          <input
            ref={inputRef}
            className="flex-grow-[2] mr-[-1px] border border-gray-500 px-4"
            type="text"
            value={code}
            placeholder={"code"}
            onChange={(e) => {
              setCode(e.target.value.toUpperCase())
              setCodeError(null)
            }}
            onKeyDown={(e) =>
              e.key === "Enter" && submitCodeButtonRef.current.click()
            }
          />
          <div className="flex-grow-[1]">
            <SecondaryButton
              buttonRef={submitCodeButtonRef}
              onClick={handleSubmit}
              autoResetOnSuccess={true}
              errorDuration={1000}
            >
              OK
            </SecondaryButton>
          </div>
        </div>
      </div>
    </div>
  )
}
